import Dropdown from "components/Dropdown/Dropdown";
import React, { createContext, useContext, useState } from "react";

const SecureComEnvContext = createContext("production");

export default SecureComEnvContext;

export const SecureComEnvContextProvider = (props) => {
  return (
    <SecureComEnvContext.Provider
      {...props}
      value={useState(process.env.REACT_APP_SECURECOM_ENV)}
    />
  );
};

export const useSecureComEnv = () => {
  const [env] = useContext(SecureComEnvContext);
  return env;
};

export const useIsProduction = () => useSecureComEnv() === "production";

export const useVkUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://apid.securecomwireless.com";
    case "dev2":
      return "https://apid2.securecomwireless.com";
    case "prod_eu":
      return "https://api.eu.securecomwireless.com";
    default:
      return "https://api.securecomwireless.com";
  }
};

export const useDealerUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://dealerapid.securecomwireless.com";
    case "dev2":
      return "https://dealerapid2.securecomwireless.com";
    case "prod_eu":
      return "https://dealerapi.eu.securecomwireless.com";
    default:
      return "https://dealerapi.securecomwireless.com";
  }
};

export const useOdataUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://odd.securecomwireless.com";
    case "dev2":
      return "https://odd2.securecomwireless.com";
    case "prod_eu":
      return "https://od.eu.securecomwireless.com";
    default:
      return "https://od.securecomwireless.com";
  }
};

export const useJobsUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://jobsd.securecomwireless.com";
    case "dev2":
      return "https://jobsd2.securecomwireless.com";
    case "prod_eu":
      return "https://jobs.eu.securecomwireless.com";
    default:
      return "https://jobs.securecomwireless.com";
  }
};

export const usePayServerUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://paysrvd.securecomwireless.com";
    case "dev2":
      return "https://paysrvd2.securecomwireless.com";
    case "prod_eu":
      return "https://paysrv.eu.securecomwireless.com";
    default:
      return "https://paysrv.securecomwireless.com";
  }
};

export const useHlsStreamerUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://hlsd.securecomwireless.com";
    case "dev2":
      return "https://hlsd2.securecomwireless.com";
    case "prod_eu":
    default:
      return "https://hls.securecomwireless.com";
  }
};

export const useThirdPartyVideoUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://vsapid.securecomwireless.com";
    case "dev2":
      return "https://vsapid2.securecomwireless.com";
    case "prod_eu":
      return "https://vsapi.eu.securecomwireless.com";
    default:
      return "https://vsapi.securecomwireless.com";
  }
};

export const useCamectUrl = () => {
  switch (useSecureComEnv()) {
    case "dev1":
      return "https://camectd.securecomwireless.com";
    case "dev2":
      return "https://camectd2.securecomwireless.com";
    case "prod_eu":
      return "https://camect.eu.securecomwireless.com";
    default:
      return "https://camect.securecomwireless.com";
  }
};

export const SecureComEnvDropdown = () => {
  const [env, setEnv] = useContext(SecureComEnvContext);
  const options = ["dev1", "dev2", "prod_eu", "production"];

  return (
    <div
      style={{
        position: "fixed",
        bottom: "150px",
        right: "30px",
        width: "120px",
        zIndex: 9999,
      }}
    >
      <Dropdown
        options={options}
        onChange={setEnv}
        selectedIndex={options.indexOf(env)}
      />
    </div>
  );
};
